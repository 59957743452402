.margin-bottom-md {
  margin-bottom: $margin-md; }

.margin-bottom-sm {
  margin-bottom: $margin-sm; }

.margin-top-sm {
  margin-top: $margin-sm; }

.scroll-block {
  max-height: 400px;
  overflow-y: auto; }

.margin-left-lg {
  margin-left: $margin-lg; }

.margin-left-sm {
  margin-left: $margin-sm; }

.margin-left-xsm {
  margin-left: $margin-xsm; }

.margin-top-lg {
  margin-top: $margin-lg; }

.margin-left-sm-lg {
  margin-left: $margin-sm + 5; }

.cursor-pointer {
  cursor: pointer; }

.dc-map {
  width: 100%;
  height: 400px; }

.text-bold {
  font-weight: bold; }
