.dc__checkbox {
  width: 100%;

  .form-group input[type="checkbox"] {
    display: none; }

  .form-group input[type="checkbox"] + .btn-group > label span {
    width: 20px; }

  .form-group input[type="checkbox"] + .btn-group > label span:first-child {
    display: none; }

  .form-group input[type="checkbox"] + .btn-group > label span:last-child {
    display: inline-block; }

  .form-group input[type="checkbox"]:checked + .btn-group > label span:first-child {
    display: inline-block; }

  .form-group input[type="checkbox"]:checked + .btn-group > label span:last-child {
    display: none; }

  .fa-check {
    color: #5cb85c;
    font-size: 19px; }

  .dc__checkbox-btn {
    background-color: #eceeef;
    border-radius: 30px !important;
    width: 33px;
    height: 33px;

    span:first-child {
      position: relative;
      right: 6px;
      bottom: 1px; } } }
