@import "../../../node_modules/font-awesome/css/font-awesome.css";
@import "../../../node_modules/bootstrap/dist/css/bootstrap.css";
@import "../../../node_modules/sweetalert2/dist/sweetalert2.min.css";
@import "../../../node_modules/ng-pick-datetime/assets/style/picker.min.css";

@import "variables";

@import "core/dc-modal";
@import "core/dc-button";
@import "./core/dc-checkbox";
@import "./core/dc-pagination";
@import "core/text";

@import "./base";
@import "./common";

//---------------------------------------------------/

.dc-spinner {
  margin: 50px auto;

  .dc-spinner__animate {
    width: 200px;
    height: 200px;
    position: relative;
    * {
      box-sizing: border-box; }

    .ball {
      height: 100%;
      width: 100%;
      background: #8BC34A;
      position: absolute;
      border-radius: 50%;
      border: 0px solid #99d651;
      animation: pulse 3s infinite; }

    .ball-2 {
      animation-delay: -1s; }

    .ball-3 {
      animation-delay: -1.5s; }

    .ball-4 {
      animation-delay: -2.25s; }

    @keyframes pulse {
      0% {
        transform: scale(0.1, 0.1);
        opacity: 1;
        border-width: 50px; }

      100% {
        transform: scale(1, 1);
        opacity: 0;
        border-width: 0; } } }

  .dc-spinner__text {
    margin-top: 7px;
    color: #8BC34A;
    animation: pulse-text 0.5s infinite;
    font-size: 22px; }

  .text-1 {
    animation-delay: 3s;
    position: relative;
    left: 6px; }

  .text-2 {
    animation-delay: 2s;
    position: relative;
    left: 9px;
    bottom: 32px; }

  .text-3 {
    animation-delay: 1s;
    position: relative;
    left: 11px;
    bottom: 64px; }

  @keyframes pulse-text {
    0% {
      opacity: 1; }

    100% {
      opacity: 0; } } }
