.dc-pagination {
  text-align: center;


  ngb-pagination {
    display: inline-block;

    .page-link {
      color: darken($main-color-green, 20%); }

    .page-item.active .page-link {
      background-color: $main-color-green;
      border-color: $main-color-green; } } }
