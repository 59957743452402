.d-btn-circle {
  position: fixed;
  right: 40px;
  bottom: 40px;
  border-radius: 50%;
  color: white;
  width: 50px;
  height: 50px;
  vertical-align: middle;
  line-height: 50px;
  text-align: center;
  font-size: 33px;
  border: none;
  padding: 4px;
  &.d-btn-circle-success {
    box-shadow: 0 0 2px 1px #8bc34a;
    background-color: #8bc34a;
    &:hover {
      background-color: #8bc34a; } } }


.btn-success {
  background-color: #8bc34a;
  border-color: #8bc34a; }

.btnRecover {
  cursor: pointer;
  margin-right: 55px;
  position: absolute;
  top: 20px;
  right: 20px;
  color: #8bc34a;
  opacity: 0.7; }

.btnRecover:hover {
  color: #8bc34a;
  opacity: 1; }

.btn-edit-list {
  margin-left: 10px; }

.btnRemove {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  color: #c83845;
  opacity: 0.7; }

.btnRemove:hover {
  color: #ff2c44;
  opacity: 1; }

.smallBtnRemove {
  cursor: pointer;
  position: absolute;
  //top: 20px
  //right: 20px
  color: #c83845;
  opacity: 0.7; }

.smallBtnRemove:hover {
  color: #ff2c44;
  opacity: 1; }

.btnRecoverList {
  cursor: pointer;
  font-weight: bold;
  //position: absolute
  top: 20px;
  right: 20px;
  color: #8bc34a;
  opacity: 0.7; }

.btnRecoverList:hover {
  color: #8bc34a;
  opacity: 1; }

.btnRemoveList {
  cursor: pointer;
  font-weight: bold;
  //position: absolute
  top: 20px;
  right: 20px;
  color: #c83845;
  opacity: 0.7; }

.btnRemoveList:hover {
  color: #ff2c44;
  opacity: 1; }

.btn-group {
  label {
    cursor: pointer;
    padding: .5rem .8rem;
    margin: 0; }
  .active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125); }
  i {
    color: #00b3ee; } }

.btn {
  &-outline {
    &-primary {
      background-color: transparent;
      color: #00b3ee;
      border-color: #00b3ee;
      &:hover {
        background-color: #00b3ee;
        color: white;
        border-color: transparent; } } } }

button {
  cursor: pointer; }
