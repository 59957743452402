body {
  background-color: #e4e5e6;
 }  //zoom: 0.7

.row-section {
  margin-bottom: 1rem; }

.bg-faded {
  background-color: #f7f7f7; }

.card {
  .card-header {
    background-color: #eceff1;
    line-height: 39px;
    padding: 0.55rem 1.25rem;
    font-size: 1.2rem;
    &__buttons {
      button {
        float: right;
        margin-left: 10px; } } } }

table {
  tr {
    cursor: pointer; } }

.empty-symbol {
  text-align: center;
  display: inherit;
  font-size: 300px;
  color: #a4aaaa;
  margin: 20px 20px 20px 20px; }

.empty-text {
  color: #a4aaaa; }

.dc-badge {
  margin: 3px 5px;
  font-size: 17px;
  font-weight: normal;
  .dc-badge__close-btn {
    padding-left: 5px;
    font-size: 19px; } }

.remove-img-button {
  background-color: #8bc34a;
  color: #e4e5e6; }

.remove-img-button:hover {
  background-color: #7aab43;
  color: #bdbdbe; }


.set-landscape-btn {
  background-color: #be67c3;
  color: #e4e5e6; }

.set-landscape-btn:hover {
  background-color: #bc51c3;
  color: #bdbdbe; }


.set-portrait-btn {
  background-color: #3d70c3;
  color: #e4e5e6; }

.set-portrait-btn:hover {
  background-color: #393bab;
  color: #bdbdbe; }

.tag-img-container {
  width: 30px;
  height: 30px; }

.log-card {
  background-color: #333;
  border-color: #333; }

.trash-bin {
  position: absolute;
  bottom: 0;
  width: 100%;
  &.active {
    color: #fff;
    background-color: #20a8d8; } }

.CodeMirror {
  height: 350px; }
